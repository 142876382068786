<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <b-card v-if="form != null">


        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> {{form.form_name | capitalize }}</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          

          <!-- <b-col md="6">
              
              <b-form-group
                  label="Location"
                  class="required"

                >
                  <b-form-select v-model="location_id">

                    <b-form-select-option value="">Select</b-form-select-option>
                    <b-form-select-option :value="l._id" v-for="l in allLocations" :key="l._id">{{l.name | capitalize}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col> -->

          <b-col md="4">
              
              <b-form-group
                  label="Audit By"
                  class="required"

                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="supervisor_name"
                  />
                    
                </b-form-group>
              
          </b-col>
          
          <b-col md="4">
              
              <!-- <b-form-group label="Inspection Date" class="required">
                <b-form-datepicker
                  v-model="checked_date"
                  :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                  locale="en"
                  :max="maxDate"
                />  
              </b-form-group> -->
              <b-form-group label="Inspection Date Time" class="mobile_tab_margin_bottom_zero required">
                  <date-picker v-model="checked_date_time" 
                    format="DD MMM YYYY HH:mm" 
                    type="datetime"
                    placeholder="Select"
                    valueType="format"
                    :disabled-date="disabledDate"
                    :disabled-time="disabledTime"
                    :clearable=false
                    :confirm=true
                    lang="en"                   
                    :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  ></date-picker>
                </b-form-group>
              
          </b-col>
          
        </b-row> 
    
      </b-card>

    <div v-if="items != null">
     
      <b-form @submit="formSubmit">
      
        <div v-for="(c,index) in items">
          <b-card>

              <b-row class="mb-1">
                  <b-col md="1">
                    <h5> {{String.fromCharCode(65+index)}}</h5>
                  </b-col>
                  <b-col md="7">
                    <h5> {{c.unit_name}} </h5>
                  </b-col>
                  <b-col md="1">
                    <h5> Pass </h5>
                  </b-col>
                  <b-col md="1">
                    <h5> Fail </h5>
                  </b-col>
                  <b-col md="1">
                    <h5> N/A </h5>
                  </b-col>
                  <b-col md="1">
                    
                    <b-button
                      variant="warning"
                      class="mt-0 ml-50 mr-0 float-left"
                      @click="addElement(index)"
                      size="sm"
                    >
                      <feather-icon icon="PlusIcon" />
                      
                    </b-button>

                  </b-col>
              </b-row>

              <b-row class="mb-1" v-for="(e,i) in c.elements" :key="i+100">
                  <b-col md="1">
                    <p> {{i + 1}}</p>
                  </b-col>
                  <b-col md="7">
                    <p v-if="typeof e.temp == 'undefined'"> {{e.name}} </p>
                    
                      <b-form-input
                        v-if="typeof e.temp != 'undefined'"
                        v-model="e.name"
                        class="form-control"
                        type="text"
                      />
                  </b-col>
                  <b-col md="1">
                    <b-form-checkbox
                      value="yes"
                      class="custom-control-warning"
                      @change="updateCheckbox($event,index,i,'pass')"
                      v-model="e.pass"
                    />
                  </b-col>
                  <b-col md="1">
                    <b-form-checkbox
                      value="yes"
                      class="custom-control-danger"
                      v-model="e.fail"
                      @change="updateCheckbox($event,index,i,'fail')"
                    />
                  </b-col>
                  <b-col md="1">
                    <b-form-checkbox
                      value="yes"
                      class="custom-control-secondary"
                      v-model="e.na"
                      @change="updateCheckbox($event,index,i,'na')"
                    />
                  </b-col>
                  <b-col md="1">

                    <b-button
                      variant="danger"
                      class="mt-0 ml-50 mr-0 float-left"
                      @click="removeElement(index,i)"
                      size="sm"
                      v-if="typeof e.temp != 'undefined'"
                    >
                      <feather-icon icon="XIcon" />
                      
                    </b-button>
                    
                  </b-col>
              </b-row>


              
          </b-card>
        </div>

        <b-card v-if= "ga.length > 0">

              <b-row class="mb-1">
                  
                  <b-col md="9">
                    <h5> General Awareness </h5>
                  </b-col>
                  <b-col md="1">
                    <h5> Yes </h5>
                  </b-col>
                  <b-col md="1">
                    <h5> No </h5>
                  </b-col>
                  <b-col md="1">
                    <!-- <h5> N/A </h5> -->
                  </b-col>
              </b-row>

              <b-row class="mb-1" v-for="(g,i) in ga" :key="i">
                  
                  <b-col md="9">
                    <p> {{g.name}} </p>
                  </b-col>
                  <b-col md="1">
                    <b-form-checkbox
                      value="yes"
                      class="custom-control-warning"
                      @change="updateGACheckbox($event,i,'pass')"
                      v-model="g.pass"
                    />
                  </b-col>
                  <b-col md="1">
                    <b-form-checkbox
                      value="yes"
                      class="custom-control-danger"
                      v-model="g.fail"
                      @change="updateGACheckbox($event,i,'fail')"
                    />
                  </b-col>
                  <b-col md="1">
                    
                  </b-col>
                  
              </b-row>
        </b-card>

        <b-card>

          <b-row>
              <b-col md="12">
                  
                  <b-form-group
                      label="Additional Comment"
                      class=""
                    >
                      <b-form-textarea
                        v-model="comment"
                        placeholder="upto 120 characters"
                        rows="4"
                      />
                        
                    </b-form-group>
                  
              </b-col>
            </b-row>

            <b-row>

                <b-col
                  cols="5"
                  class="mb-2 mt-2"
                > 
                </b-col>
                <b-col
                  cols="2"
                  class="mb-2 mt-2"
                > 

                  <b-row>
                
                    <b-col
                      cols="12"
                      class="mb-2 mt-2"
                      ><center>
                        <h4 class="mb-0">
                          Signature
                        </h4>
                        </center>
                      </b-col>
                  </b-row>

                  <b-row>
                      <b-col
                        cols="12"
                        class="mb-2 mt-0"
                      > 
                    
                          <b-media class="mb-2 mt-2" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                            <template #aside>
                              <b-avatar
                                ref="supervisorS"
                                :src="doc_icon"
                                variant="light-info"
                                size="200px"
                                rounded
                              />
                            </template>

                            <div class="d-flex flex-wrap">
                              <input
                                ref="supervisorSInput"
                                type="file"
                                class="d-none"
                                @input="supervisorImageUpload"
                              >
                            </div>

                            
                          </b-media>


                          <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                        
                          <center>
                            <b-button
                                variant="danger"
                                class="mt-1 ml-2"
                                v-if="supervisorSign.image != null"
                                @click="removeSign('supervisor')"
                              >
                              <feather-icon icon="XIcon" />
                              
                            </b-button>
                          </center>


                        </b-col>
                  </b-row>
                </b-col>
            </b-row>

            <b-row>
              <b-col>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class = "mr-1"
                  variant="outline-secondary"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>

                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" v-if="id != null" @click="discardItem(id)">
                    Discard Draft
                </b-button>
          

                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="status = 'draft'">
                    Save As Draft
                </b-button>
          

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mr-1"
                  type="submit"
                  @click="status = 'completed'"
                >
                  Submit
                </b-button>

              </b-col>
          </b-row>

        </b-card>
      </b-form>

      <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

      
          
    </div>

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox ,BBreadcrumb,BFormDatepicker
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

var moment = require('moment-timezone');
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BBreadcrumb,BFormDatepicker,DatePicker
  },

  computed: {
    maxDate() {
      return new Date();
    },
  },
  
  data() {
    return {
      checked_date_time : moment().format('DD MMM YYYY HH:mm'),
      id:null,
      status:'completed',
      items:null,
      form:null,
      ga:[],
      crew:'',
      allCrew:[],
      comment:'',
      location_id:'',
      allLocations:[],

      supervisor_name:this.$store.getters.currentUser.full_name,

      showDismissibleAlert:false,

      images:[{
        image:null,
        name:'',
      }],
      clientSign : {
        image:null,
        name:'',
      },
      supervisorSign : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      openedSignModel:null,

      
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      tempSign : {
        image:null,
        name:'',
      },
      show_default:false,
      having_default:false,
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    disabledDate(date){
      var today = moment().tz('Asia/Singapore');
      return date >= today;
    },

    disabledTime(date, currentValue){
      return date >= moment().tz('Asia/Singapore');
    },

    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },
    
    variantColor(status){
      if (status == 'assigned') {
        return 'primary';
      }else if(status == 'in_progress'){
        return 'warning'
      }else{
        return 'success';
      }
    },

    checklistDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id,
           },
           api: '/api/assigned-qa-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.items = this.$store.getters.getResults.data.checklist;
                this.form = this.$store.getters.getResults.data.form;
                this.ga = this.$store.getters.getResults.data.ga;
                //this.allLocationsList();
                if (this.$store.getters.getResults.data.id != null) {
                  this.id = this.$store.getters.getResults.data.id;
                }

                if (this.$store.getters.getResults.data.supervisor_name != null) {
                  this.supervisor_name = this.$store.getters.getResults.data.supervisor_name;
                }

                if (this.$store.getters.getResults.data.signature != null) {
                    this.supervisorSign = {
                      image:this.$store.getters.getResults.data.signature,
                      name:'',
                      type: 'supervisor',
                      default:'yes'
                    };
                }

                if (this.$store.getters.getResults.data.comment != "") {
                  this.comment = this.$store.getters.getResults.data.comment;
                }

                return this.items;
            }
        });
    },
    
    allLocationsList(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
             type:'QI'
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.allLocations = this.$store.getters.getResults.data;
                return this.allLocations;
            }
        });
    },
    updateCheckbox(e,index,i,type){
      if (e) {
        if (type == 'pass') {

          this.items[index].elements[i].fail = '';
          this.items[index].elements[i].na = ''

        }else if (type == 'fail') {
          this.items[index].elements[i].pass = '';
          this.items[index].elements[i].na = ''
        }else{
          this.items[index].elements[i].fail = '';
          this.items[index].elements[i].pass = ''
        }
      }else{
        this.items[index].elements[i].pass = '';
        this.items[index].elements[i].fail = '';
        this.items[index].elements[i].na = ''
      }

      var j = 0;
        
      for (var i = 0; i < this.items[index].elements.length; i++) {

        if (this.items[index].elements[i].pass == 'yes') {
          j++
        }else if (this.items[index].elements[i].fail == 'yes') {
          j++
        }else if (this.items[index].elements[i].na == 'yes') {
          j++
        }
      }

      if (j > 0) {
        this.items[index].checked = 'yes';
      }else{
        this.items[index].checked = 'no';
      }
    },
    updateGACheckbox(e,index,type){
      if (e) {

        if (type == 'pass') {

          this.ga[index].fail = '';
        }else {
          this.ga[index].pass = '';
        }

      }else{
        this.ga[index].fail = '';
        this.ga[index].pass = '';
      }
    },
    
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;
                this.supervisorSign.type =  'supervisor';
                this.supervisorSign.default = 'no';
                

            }
          }
      }
    },
    
    removeSign(sign){

      this.supervisorSign.image = null;
      this.supervisorSign.name = '';
      this.supervisorSign.default = 'no';
    },
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             checked_date_time: moment(this.checked_date_time, 'DD MMM YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
             supervisor_name:this.supervisor_name,
             supervisorSign:this.supervisorSign,
             checklists:this.items,
             comment:this.comment,
             ga:this.ga,
             form_id:this.$route.params.id,
             status:this.status,
             id:this.id

           },
           api: '/api/submit-quality-audit'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                
                var data = this.$store.getters.getResults.data;
                

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: this.$store.getters.getResults.message,
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.$router.go(-1);
                });

                //return data;
            }
        });
    },
    addElement(index){
      var obj = {
        temp:'true',
        name : "",
        pass:"yes",
        fail:"",
        na:""
      }
      this.items[index].elements.push(obj);
    },
    removeElement(index,k){
      this.items[index].elements.splice(k,1);

      var j = 0;
        
      for (var i = 0; i < this.items[index].elements.length; i++) {

        if (this.items[index].elements[i].pass == 'yes') {
          j++
        }else if (this.items[index].elements[i].fail == 'yes') {
          j++
        }else if (this.items[index].elements[i].na == 'yes') {
          j++
        }
      }

      if (j > 0) {
        this.items[index].checked = 'yes';
      }else{
        this.items[index].checked = 'no';
      }
    },
    discardItem(id){
        var msg = 'Are you sure want to discard this record?';

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        id : id,
                        
                    },
                    api : "/api/discard-qa",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.showAlert();
                    } else {
                        this.successAlert()
                        .then(() => {
                            this.$router.go(-1);
                        });
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      if (this.openedSignModel == 'supervisor') {

         this.$refs['supervisorSInput'].click();
      }

      if (this.openedSignModel == 'inspected_by') {

         this.$refs['inspectedSInput'].click();
      }

      this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        if (this.openedSignModel == 'supervisor') {
          
          this.supervisorSign.image = data;
          this.supervisorSign.name = 'siganture.png';
          this.supervisorSign.type = 'supervisor';
          this.supervisorSign.default = 'no';

          
        }else{

          this.clientSign.image = data;
          this.clientSign.name = 'signature.png';
          

        }

        this.$refs['signatureModel'].hide();
      }
    },
    saveDefault(){
      
      if (this.openedSignModel == 'supervisor') {
                    
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        
      }else{

        this.clientSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type: 'client',
          default:'yes'
        }
      }

      this.$refs['signatureModel'].hide();
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:{name:'update-checklists'},
        text: 'Create Checklists'
      },{
        to:null,
        text: 'Form Detail',
        active:true
      }];
      return item;
    }
    
    
  },
  mounted(){
    this.useDefault();
    this.checklistDetail();
    
    
    
  }
  
  
}
</script>

<style>

</style>
